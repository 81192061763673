<template>
  <v-date-picker is-range v-model="state.range" @dayclick="dayClick">
    <template v-slot="{ inputValue, inputEvents }">
      <div class="form-group__input">
        <span>
          <label class="t-lbl">{{ $t("message.start_date") }}</label>
          <div class="clearfix"></div>
          <input
            class="bg-white form-input border br1 px-2 py-1"
            :value="inputValue.start"
            v-on="inputEvents.start"
          />
        </span>
        <span>
          <label class="t-lbl">{{ $t("message.end_date") }}</label>
          <div class="clearfix"></div>
          <input
            class="bg-white form-input border br2 px-2 py-1"
            :value="inputValue.end"
            v-on="inputEvents.end"
          />
        </span>
        <span>
          <label>&nbsp;</label>
          <button @click="filterByDates" class="form-btn ml-10">
            {{ $t("message.update") }}
          </button>
        </span>
      </div>
    </template>
  </v-date-picker>
  <div class="clearfix"></div>
  <br />
  <div class="journal--master" v-if="state.journals.length">
    <div class="journal-card m0">
      <div class="journal-card__header">
        <h3 class="journal-card__title">{{ $t("message.general_ledger") }}</h3>
      </div>
      <br />
      <div class="grid d" style="--c: 2fr 1fr 0.5fr 0.5fr">
        <span class="t-lbl">Prohada 1.0.0</span>
        <span class="t-lbl"
          >Date de tirage: {{ new Date().toLocaleString() }}</span
        >
        <span class="t-lbl">Time: 17:26:00</span>
      </div>
      <br />
      <div class="journal-card__body">
        <div
          class="jRow head"
          style="--col: 0.8fr 0.75fr 1.5fr 1.5fr 1.75fr 1fr 1fr 1fr"
        >
          <span>Jour</span>
          <span>N<sup>◦</sup> pièce</span>
          <span>N<sup>◦</sup> compte</span>
          <span>N<sup>◦</sup> tiers</span>
          <span>Libellè ècriture</span>
          <span class="dib t-r">Mvts dèbit</span>
          <span class="dib t-r">Mvts crèdit</span>
          <span class="dib t-r">Équilibre progressif</span>
        </div>

        <div class="jTable-loading" v-if="isLoading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="jRow-body" v-if="!isLoading">
          <div
            class="jRow"
            v-for="journal in state.journals"
            :key="journal.id"
            style="--col: 0.8fr 0.75fr 1.5fr 1.5fr 1.75fr 1fr 1fr 1fr"
          >
            <span class="jRow-col">{{ journal.entry_date }}</span>
            <span class="jRow-col">{{ journal.part_number }}</span>
            <span class="jRow-col">{{ journal.account_number }}</span>
            <span class="jRow-col">{{ journal.tier_number }}</span>
            <span class="jRow-col">{{ journal.designation }}</span>
            <span class="jRow-col t-r">{{ journal.debit }}</span>
            <span class="jRow-col t-r">{{ journal.credit }}</span>
            <span class="jRow-col t-r">{{ journal.progressive_balance }}</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div>
          <button
            @click="generatePdf"
            v-if="!pdf.generating"
            class="form-btn sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-file-text"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path
                d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
              />
              <line x1="9" y1="9" x2="10" y2="9" />
              <line x1="9" y1="13" x2="15" y2="13" />
              <line x1="9" y1="17" x2="15" y2="17" /></svg
            >&nbsp;{{ $t("message.pdf") }}
          </button>
          <button class="form-btn disabled" disabled v-if="pdf.generating">
            Generating...
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && state.journals.length == 0"
    class="journal--master main-lbl"
  >
    <div class="journal-card m0 second-lbl">
      <div class="journal-card__body">
        <span class="lbl-gray__empty">No Data Found</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import router from "@/router";
export default {
  setup() {
    const isLoading = ref(1);
    const prevDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const state = reactive({
      range: {
        start: prevDate,
        end: new Date(),
      },
      journals: [],
      periode_du: new Date(),
    });
    const pdf = ref({ generating: 0 });
    const store = useStore();
    function filterByDates() {
      // state.journals = [];
      fetchJournalEntries();
    }
    const client_uuid = computed(() => router.currentRoute.value.params.uuid);
    const fetchJournalEntries = async () => {
      isLoading.value = 1;
      await store
        .dispatch("journal/fetchEntries", {
          from_date: state.range.start,
          to_date: state.range.end,
          client_uuid: client_uuid.value,
          operation_type: "general-ledger",
          isGroupedBy: 0,
          ohada_codes: [401, 411, 521, 571, 658],
        })
        .then((response) => {
          console.log(response);
          isLoading.value = 0;
          state.journals = response;
        });
    };
    const generatePdf = async () => {
      pdf.value.generating = 1;

      const pdfData = {
        from_date: state.range.start,
        to_date: state.range.end,
        ohada_codes: [401, 411, 521, 571, 658],
        operation_type: "general-ledger",
        print_date: state.periode_du.toLocaleString(),
      };
      if (client_uuid.value !== undefined) {
        pdfData.client_id = state.client.id;
      }
      await store.dispatch("journal/generatePdf", pdfData).then((res) => {
        pdf.value.generating = 0;
        if (res.status == "success") {
          let linkElement = document.createElement("a");
          linkElement.setAttribute("href", res.data);
          linkElement.setAttribute("target", "_blank");
          document.body.appendChild(linkElement);
          linkElement.click();
          document.body.removeChild(linkElement);
        }
      });
    };
    onMounted(async () => {
      store.commit("component/SET_LOADER", false);
      fetchJournalEntries();

      if (!state.client)
        store
          .dispatch("client/fetchClientByUUID", { uuid: client_uuid.value })
          .then((res) => {
            state.client = res.data;
          });
    });
    return {
      state,
      pdf,
      fetchJournalEntries,
      generatePdf,
      isLoading,
      filterByDates,
    };
  },
};
</script>
